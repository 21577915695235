import { ChicletTypes } from 'cat-ecommerce-alloy';

export const VARIANT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  CANCELED: 'in-determined',
  ERROR: 'error',
  DEFAULT: undefined,
  CAUTION: 'caution'
};

export const NUMBER_REGEX = '[0-9]+$';
export const ALPHA_NUMERIC_REGEX = '^[a-zA-Z0-9]+$';
export const RTL_LANGUAGES = ['-23', '-33'];
export const ADDRESS_TYPE_BILLING = 'B';
export const ADDRESS_TYPES_BILLING = ['B', 'SB'];
export const ADDRESS_TYPE_SHIPPING = 'S';
export const ADDRESS_TYPE_CSTO = 'CSTO';
export const APPROVE_ORDER = 'A';

export const BILLING_METHOD_BANK_TRANSFER = 'BankTransfer';
export const BILLING_METHOD_EXISTING_CC = 'SimplePunchout_';
export const BILLING_METHOD_NEW_CC = 'SimplePunchout';
export const BILLING_METHOD_NEW_CAT_CARD = 'CatCommercialCard';
export const BILLING_METHOD_CAT_PREPAID_CERT = 'CatCredits';
export const BILLING_METHOD_CAT_CREDITS_ACCOUNT_SAVED = 'CatCredits_';
export const BILLING_METHOD_SAVED_CAT_CARD = 'CatCommercialCard_';
export const BILLING_METHOD_CAT_CARD = 'Cat Card';
export const BILLING_METHOD_CAT_CREDIT = 'Cat Credits';
export const BILLING_METHOD_CAT_VANTAGE_REWARDS = 'CatVantage';
export const BILLING_METHOD_PIX = 'PIX';
export const REGEX_SAVED_CAT_CARD = /CatCommercialCard_/;
export const REGEX_EXISTING_CC = /SimplePunchout_/;
export const MAX_CAT_CREDITS_ALLOWED = 10;
export const BILLING_METHOD_PRICING_LIMIT = [
  BILLING_METHOD_EXISTING_CC,
  BILLING_METHOD_NEW_CC,
  BILLING_METHOD_CAT_PREPAID_CERT,
  BILLING_METHOD_SAVED_CAT_CARD,
  BILLING_METHOD_NEW_CAT_CARD,
  BILLING_METHOD_CAT_CARD
];

export const CATCORP_ESITE_URL = '/en/catcorp';
export const CATCORP_STORE = '21801';
export const CAT_SAVED_LIST_REDIRECT = 'CATSavedListRedirect';
export const CAT_SIS_REDIRECT = 'CATSISRedirect';
export const CAT_MY_EQUIPMENT_REDIRECT = 'CATMyEquipmentRedirect';
export const CB_STATES = {
  UNCHECKED: 'unchecked',
  CHECKED: 'checked',
  INDETERMINATE: 'indeterminate'
};

export const CC_WELCOME_MODAL_VIEW = true;
export const CC_WELCOME_MODAL_NOT_VIEW = false;
export const CONTROL_CENTER_ALLOWED_USER_AFFILIATIONS =
  'CONTROL_CENTER_ALLOWED_USER_AFFILIATIONS';
export const IS_MLP_PAGE = true;
export const IS_CAMPAIGN_LANDING_PAGE = true;
export const PM_KIT_WIDGET_SERIAL = 'PM Kit Widget Serial';
export const PM_KIT_WIDGET_MODEL = 'PM Kit Widget Model';

export const DEFAULT_COUNTRY_CODE = 'US';

export const DUMMY_PART_PRODUCT_ID = 3202002;

export const FITMENT_VARIANTS = {
  large: {
    name: 'large',
    iconSize: 24,
    classes: 'fitment-large',
    showPopup: true
  },
  small: {
    name: 'small',
    iconSize: 16,
    classes: 'fitment-small',
    showPopup: false
  }
};
export const FULFILLMENT_TYPE_PICKUP = 'PICKUP';
export const FULFILLMENT_TYPE_DELIVERY = 'DELIVERY';
export const FULFILLMENT_TYPE_DROPBOX = 'DROPBOX';

export const HOMEPAGE = 'HomePage';
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_FORBIDDEN = 403;
export const HEADER_GLOBAL_LOGIN = 'Header_GlobalLogin';

export const ITEMS_PER_PAGE = 12;
export const IS_PM = true;

export const LOCALHOST_CONTEXT_PREFIX = '/webapp/wcs/stores/servlet';
export const LOCALHOST_CONTEXT_PREVIEW_PREFIX = '/webapp/wcs/preview/servlet';

export const EQP_SEARCH_LENGTH = 3;

export const MIN_SEARCH_LENGTH = 3;
export const NOTIFICATION_PER_PAGE = 8;
export const PAYMENT_GATEWAY_BAMBORA = 'Bambora';
export const PAYMENT_GATEWAY_CYBERSOURCE = 'CyberSource';
export const PAYMENT_GATEWAY_MIDTRANS = 'MidtransSNAP';
export const PAYMENT_GATEWAY_PAYURUSSIA = 'PayuRU';
export const PAYMENT_GATEWAY_WESTPAC = 'WestPac';
export const PAYMENT_GATEWAY_OCTET = 'Octet';
export const PAYMENT_GATEWAY_PAYZEN = 'PayZen';
export const PAGE_BREAKPOINTS = {
  XXL: 1232,
  XL: 1200,
  LG: 992,
  BLOCKS_LG: 960,
  MD: 768,
  SM: 576,
  BLOCKS_SM: 560,
  XS: 400
};
export const PAGE_NUMBER = 1;
export const PAGE_SIZE_OPTIONS = [12, 24, 48, 96];

export const RECENTLY_VIEWED_PRODUCTS_DEFAULT_PAGE_SIZE = 6;

export const TIMEOUT_DEFAULT = 15000;
export const TIMEOUT_FORTY_SECONDS = 40000;
export const TIMEOUT_DOUBLED = 30000;
export const TIMEOUT_EXTENDED = 120000;

export const STATUS = {
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  PENDING: 'pending',
  IDLE: 'idle',
  ON: 'on',
  OFF: 'off',
  SHOW: 'show',
  HIDE: 'hide'
};
export const SUCCESS = 'success';

export const PENDING_ORDER_STATUS = {
  Approved: 'APPROVED_STATUS',
  Deleted: 'CAT_ORD_DELETED',
  Pending: 'PENDING_STATUS',
  Rejected: 'CAT_ORD_REJECTED',
  Expired: 'EXPIRED_STATE'
};

export const ORDER_STATUS = {
  ACTIVE: 'D',
  COMPLETED: 'F',
  DELETED: 'AD',
  EXPIRED: 'E',
  HOLD: 'B',
  PENDING: 'NEW',
  PENDING_APPROVAL: 'W',
  QUEUED: 'H',
  REJECTED: 'N',
  PP: 'PP',
  CANCELLED: 'OC',
  RELEASED: 'OR'
};

export const MAKE = {
  CAT: 'AA'
};

export const REFERENCE_LABEL_HEADER_STATUS = ['AD', 'W', 'H', 'N', 'E'];

export const ACCOUNT = 'account';
export const STATUS_FIELD = 'status';

export const UNKNOWN_ERROR_TITLE = 'Sorry, an error has occurred';
export const UNKNOWN_ERROR_MESSAGE = `We're sorry, an unknown error has occurred. Please try again. If issues persist, please contact us.`;
export const USER_TYPE_GUEST = 'G';
export const USER_TYPE_REGISTERED = 'R';
export const USER_ROLES = {
  ADMIN: 'Buyer Administrator',
  APPROVER: 'Buyer Approver',
  SUBMITTER: 'Buyer'
};
export const USER_TYPE_AUTHENTICATED = 'A';

export const ASSET_MAKE = 'CAT';
export const GENERIC_USER_ID = '-1002';
export const FALLBACK_MOUNTAIN_IMAGE =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20170508-76204-57647';

export const ENROLLED_CAT_VANTAGE_STAR =
  'https://s7d2.scene7.com/is/content/Caterpillar/CM20231214-7f82d-8062b';
export const NOT_ENROLLED_CAT_VANTAGE_STAR =
  'https://s7d2.scene7.com/is/content/Caterpillar/CM20231214-01efc-d1700';
export const CVR_ENROLLED_IMG =
  'https://s7d2.scene7.com/is/content/Caterpillar/CM20240212-77a19-f6908';
// 'https://s7d2.scene7.com/is/image/Caterpillar/CM20230203-5b781-3746f';
export const CVR_NOT_ENROLLED_IMG =
  'https://s7d2.scene7.com/is/content/Caterpillar/CM20240212-c5944-37e9e';
// 'https://s7d2.scene7.com/is/image/Caterpillar/CM20230203-8d67e-6a33e';

export const IMAGE_URL = 'https://caterpillar.scene7.com/is/image/Caterpillar/';

export const CAT_FINACIAL_SUMMARY_LINK_FOR_DEVS =
  'https://catfinancial--sit.sandbox.my.site.com/mycatfinancial/services/auth/sso/MyCatfinancial_B2C_non_prod?startURL=/s/gc-RR-Summary?show=GC_RR_RewardsReimaginedSummary';

export const CAT_FINACIAL_SUMMARY_LINK_FOR_NON_PRODUCTION =
  'https://catfinancial--train.sandbox.my.site.com/mycatfinancial/services/auth/sso/MyCatfinancial_B2C_non_prod?startURL=/s/gc-RR-Summary?show=GC_RR_RewardsReimaginedSummary';

export const CAT_FINACIAL_SUMMARY_LINK_FOR_PRODUCTION =
  'https://mycatfinancial.com/services/auth/sso/MyCatfinancial_B2C?startURL=/s/gc-RR-Summary?show=GC_RR_RewardsReimaginedSummary';

export const SCENE7_PRESETS = {
  CART: '$pcc-cart$',
  E3: '$pcc-e3$',
  PDP2: '$pcc-pdp2$'
};
// TODO: Move this to mlp?
export const IMAGE_PRESETS = '&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0';

export const LONG_TOAST_DELAY = 5000;
export const REGULAR_TOAST_DELAY = 3000;

export const NO_CACHE_HEADER = { 'Cache-Control': 'no-cache' };
export const NO_STORE_CACHE_HEADER = { 'Cache-Control': 'no-store, no-cache' };
export const GET_PRODUCTS_NO_CACHE_HEADER = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0'
};

export const AFFILIATION_DEALER = 'Dealer';
export const CHECKOUT_REQUEST_TYPE = 'checkout';
export const SUMMARY_AND_PAYMENT_REQUEST_TYPE = 'summaryAndPayment';
export const CAT_CENTRAL_LEARN_MORE = '/catcentral';
export const CAT_CARD_LEARN_MORE = '/catcard';
export const SSRO_LEARN_MORE = '/self-service-options';

export const ORDER_STATUS_MAP = {
  OP: 'SHIPPED_STATUS_OP',
  OC: 'SHIPPED_STATUS_OC',
  EX: 'SHIPPED_STATUS_EX',
  OH: 'SHIPPED_STATUS_OH',
  OR: 'SHIPPED_STATUS_OR',
  PS: 'SHIPPED_STATUS_PS',
  PU: 'SHIPPED_STATUS_PU',
  FS: 'SHIPPED_STATUS_FS',
  PA: 'PA_READY_STATUS',
  RP: 'SHIPPED_STATUS_RP',
  DL: 'SHIPPED_STATUS_DL',
  F: 'CAT_ORD_COMPLETED',
  N: 'CAT_ORD_REJECTED',
  AD: 'CAT_ORD_DELETED',
  W: 'SUBMITTED_FOR_APPROVAL',
  H: 'QUEUED',
  QX: 'QUEUED_ORDER_UNPROCESSED',
  ALL: 'CAT_ORD_ALL',
  APR: 'APPROVED_STATUS',
  NEW: 'QUOTE_EXPIRING',
  AE: 'CAT_ORD_COMPLETED',
  E: 'EXPIRED_STATE',
  PE: 'EXPIRED_STATE',
  OFFLINE_ST_AA: 'OFFLINE_ST_AA',
  OFFLINE_ST_IN: 'OFFLINE_ST_IN',
  OFFLINE_ST_CA: 'OFFLINE_ST_CA',
  OFFLINE_ST_AC: 'OFFLINE_ST_AC',
  OFFLINE_ST_HE: 'OFFLINE_ST_HE',
  OFFLINE_ST_IP: 'OFFLINE_ST_IP',
  OFFLINE_ST_ID: 'OFFLINE_ST_ID',
  OFFLINE_ST_EAA: 'OFFLINE_ST_EAA',
  OFFLINE_ST_ATE: 'OFFLINE_ST_ATE',
  OFFLINE_ST_RS: 'OFFLINE_ST_RS',
  PP: 'PENDING_PAYMENT',
  NP: 'SHIPPED_STATUS_NP'
};
export const LINE_ITEM_STATUS_MAP = {
  IT: 'SHIPPED_STATUS_IT',
  PR: 'SHIPPED_STATUS_PR',
  RD: 'SHIPPED_STATUS_RD',
  OD: 'SHIPPED_STATUS_OD',
  PA: 'SHIPPED_STATUS_ITEM_PA',
  RP: 'SHIPPED_STATUS_ITEM_RP',
  CC: 'SHIPPED_STATUS_CC',
  PS: 'SHIPPED_STATUS_ITEM_PS',
  DL: 'SHIPPED_STATUS_ITEM_DL',
  PU: 'SHIPPED_STATUS_ITEM_PU',
  IR: 'SHIPPED_STATUS_IR',
  NP: 'SHIPPED_STATUS_NP'
};

export const OFFLINE_STATUS_MAP = {
  D: 'OFFLINE_ST_AA',
  F: 'OFFLINE_ST_IN',
  C: 'OFFLINE_ST_CA',
  E: 'OFFLINE_ST_AC',
  B: 'OFFLINE_ST_HE',
  A: 'OFFLINE_ST_IP',
  X: 'OFFLINE_ST_ID',
  G: 'OFFLINE_ST_EAA',
  T: 'OFFLINE_ST_ATE',
  H: 'OFFLINE_ST_RS'
};
export const ORDER_VIEW_STATUS = [
  'OP',
  'OC',
  'EX',
  'OH',
  'OR',
  'PS',
  'FS',
  'PA',
  'RP',
  'PU',
  'DL'
];

export const NOTIFICATIONS_STATUS_MAP = {
  ...ORDER_STATUS_MAP,
  PA: 'PARTIALLY_READY_PICKUP',
  H: 'QUEUED_FOR_PROCESSING',
  RP: 'SHIPPED_STATUS_RP',
  OR: 'SHIPPED_STATUS_OR',
  EX: 'ORDER_PROBLEM',
  W: 'SUBMITTED_FOR_APPROVAL',
  APR: 'Approval_Status_Approved',
  OH: 'SHIPPED_STATUS_OH',
  OP: 'PLACED',
  OC: 'CANCELLED_STATE',
  EXP: 'EXPIRED_STATE'
};

export const NOTIFICATION_LABEL_MAP = {
  ...ORDER_STATUS_MAP,
  W: 'SUBMITTED_FOR_APPROVAL',
  APR: 'Approval_Status_Approved',
  OP: 'SHIPPED_STATUS_OP',
  OR: 'SHIPPED_STATUS_OR',
  OH: 'SHIPPED_STATUS_OH',
  EX: 'ORDER_PROBLEM',
  NEW: 'EXPIRING_TODAY',
  PA: 'PARTIALLY_READY_PICKUP',
  RP: 'SHIPPED_STATUS_RP',
  QX: 'NOT_PROCESSED',
  PROCESSED: 'SHIPPED_STATUS_OR',
  FS: 'SHIPPED_STATUS_FS',
  EXP: 'Expired',
  E: 'NOTIF_PREF_EXPIRED_APPROVAL_TITLE',
  PS: 'MO_OrderStatus_V',
  OC: 'CANCELLED_STATE'
};

export const NOTIFICATION_LABEL_TYPE_MAP = {
  W: VARIANT_TYPES.DEFAULT,
  APR: VARIANT_TYPES.SUCCESS,
  OP: VARIANT_TYPES.SUCCESS,
  OR: VARIANT_TYPES.SUCCESS,
  H: VARIANT_TYPES.SUCCESS,
  AD: VARIANT_TYPES.ERROR,
  OH: VARIANT_TYPES.ERROR,
  EX: VARIANT_TYPES.ERROR,
  N: VARIANT_TYPES.ERROR,
  OC: VARIANT_TYPES.CANCELED,
  DL: VARIANT_TYPES.SUCCESS,
  PU: VARIANT_TYPES.SUCCESS,
  NEW: VARIANT_TYPES.ERROR,
  RP: VARIANT_TYPES.CAUTION,
  PA: VARIANT_TYPES.CAUTION,
  E: VARIANT_TYPES.ERROR,
  QX: VARIANT_TYPES.ERROR,
  PROCESSED: VARIANT_TYPES.SUCCESS,
  FS: VARIANT_TYPES.CAUTION,
  PS: VARIANT_TYPES.CAUTION
};

export const ORDER_STATUS_TYPE_MAP = {
  OH: ChicletTypes.ERROR,
  OR: ChicletTypes.INFORMATIONAL,
  PS: ChicletTypes.INFORMATIONAL,
  FS: ChicletTypes.INFORMATIONAL,
  RP: ChicletTypes.SUCCESS,
  OC: ChicletTypes.ERROR,
  EX: ChicletTypes.DEFAULT,
  PU: ChicletTypes.SUCCESS,
  DL: ChicletTypes.SUCCESS,
  OP: ChicletTypes.INFORMATIONAL,
  F: ChicletTypes.INFORMATIONAL,
  N: ChicletTypes.ERROR,
  AD: ChicletTypes.ERROR,
  W: ChicletTypes.INFORMATIONAL,
  H: ChicletTypes.INFORMATIONAL,
  PA: ChicletTypes.INFORMATIONAL,
  APR: ChicletTypes.SUCCESS,
  AE: ChicletTypes.SUCCESS,
  PP: ChicletTypes.INFORMATIONAL,
  CC: ChicletTypes.INFORMATIONAL,
  RD: ChicletTypes.INFORMATIONAL,
  OD: ChicletTypes.INFORMATIONAL,
  PR: ChicletTypes.INFORMATIONAL,
  ON: ChicletTypes.INFORMATIONAL,
  IT: ChicletTypes.INFORMATIONAL,
  E: ChicletTypes.ERROR
};

export const ORDER_HISTORY_STATUS_TYPE_MAP = {
  ...ORDER_STATUS_TYPE_MAP,
  RP: ChicletTypes.INFORMATIONAL
};

export const ORDER_STATUS_NOTIFICATION_TRANSLATION_MAP = {
  OR: 'NOTIFICATION_ORDER_RELEASED',
  PS: 'NOTIFICATION_PARTIALLY_SHIPPED',
  FS: 'NOTIFICATION_FULLY_SHIPPED',
  RP: 'NOTIFICATION_PICKED_READY',
  OC: 'NOTIFICATION_CANCELLED',
  EX: 'NOTIFICATION_ORDER_EXCEPTION',
  PU: 'NOTIFICATION_PICKED_UP',
  DL: 'NOTIFICATION_DELIVERED',
  N: 'NOTIFICATION_REJECTED',
  AD: 'NOTIFICATION_DELETED',
  PA: 'NOTIFICATION_ORDER_PARTIAL_PICKUP',
  H: 'NOTIFICATION_QUEUED_PROCESSING',
  W: 'NOTIFICATION_PENDING_APPROVAL',
  OP: 'NOTIFICATION_APPROVED',
  OH: 'NOTIFICATION_ON_HOLD',
  APR: 'NOTIFICATION_APPROVED',
  QX: 'NOTIFICATION_FULLY_SHIPPED',
  EXP: 'NOTIFICATION_FULLY_SHIPPED'
};

export const ORDER_TYPE = {
  ORD: { description: 'Regular Order', value: 'ORD' },
  PRL: { description: 'Private Saved list', value: 'PRL' },
  SRL: { description: 'Shared Saved list', value: 'SRL' },
  STD: { description: 'Standing Order', value: 'STD' },
  QOT: { description: 'Quotation Order', value: 'QOT' },
  QUK: { description: 'Profile Order', value: 'QUK' },
  REC: { description: 'Recurring Order', value: 'REC' },
  SUB: { description: 'Subscription Order', value: 'SUB' }
};

export const UOM = {
  metric: 'metric',
  us: 'us',
  null: 'null',
  c62: 'C62'
};

export const PRODUCT_VARIANTS = {
  pdp_overview: 'pdp_overview',
  card: 'card'
};

export const ESPOT_VARIANTS = {
  PINSTRIPE: 'PINSTRIPE',
  CAROUSEL: 'CAROUSEL',
  LARGE: 'LARGE'
};

export const REPLACEMENT_LIST = 'PCC PDP Replacement Parts';
export const PARTIALLY_READY_PICKUP = 'PA';
export const BEST_SELLER_GA_LIST_NAME = 'PCC ALP TOP SELLING PARTS CATEGORY';
export const HP_RECENT_VIEW_GA_LIST_NAME = 'PCC HP Recently Viewed';
export const LUCID_FEATURE_PRODUCT_GA_LIST_NAME =
  'PCC Home Page Featured products';
export const BEST_SELLER_PARTS_DEFAULT_QUANTITY = 1;

export const PROD_URL = {
  href: 'https://parts.cat.com',
  name: 'Parts.cat.com'
};

export const DEALER_LIST = 'DFL';
export const PUBLIC_LIST = 'Z';
export const PRIVATE_LIST = 'Y';
export const SAVED_LIST_TYPES = {
  [DEALER_LIST]: 'dealer',
  [PUBLIC_LIST]: 'shared',
  [PRIVATE_LIST]: 'private'
};
export const SAVED_LIST_DETAILS_ROW_VALUES = {
  quantity: '',
  itemNumber: '',
  assetId: '',
  lineItemNote: '',
  customerPartNumber: '',
  customerItemNumber: ''
};
export const FITMENT_CHECK = {
  FULL_CHECK: 'fullCheck',
  PARTIAL_CHECK: 'partialCheck',
  NO_CHECK: 'noCheck'
};

export const LIST_MANAGER_SORT_DIRECTION = {
  ASC: 1,
  DESC: -1
};

export const CAT_CARDS_ALLOWED_COUNTRIES =
  'PCC_FEATURE_CATCARDS_ALLOWED_COUNTRIES';
export const CAT_CENTRAL_ALLOWED_COUNTRIES =
  'PCC_FEATURE_CATCENTRAL_ALLOWED_COUNTRIES';
export const PCC_OMM_ALLOWED_LANGUAGES = 'PCC_FEATURE_OMM_ALLOWED_LANGUAGES';
export const OMM_BOOK_COOKIE = {
  BOOK_DETAILS: 'ommBookDetails'
};
export const BUCKET_GET_SUBCATEGORY_NUMBER = '97001';
export const BUCKET_MODEL_NUMBER_FACET_ID = 'bucketModelNumber';
export const MOTOR_GRADER_MODEL_NUMBER_FACET_ID = 'motorGraderModelNumber';
export const APPLICATION_AND_MATERIAL_FACET_ID = 'ads_f70002_ntk_cs';

export const BUCKET_GET_SUBCATEGORY = 'Bucket GET';
export const BUCKET_MODEL_NUMBER_FACET = 'Bucket model number';
export const HOSE_WARNING = 'HoseWarning';

export const ISO_DATE_FORMAT = {
  SHORT: 'yyyy-MM-dd',
  LARGE: "yyyy-MM-dd'T'HH:mm:ssxxxx",
  SHORT_DATE_TIME: 'yyyy-MM-dd HH:mm:ss'
};

export const LONG_DATE_FORMAT_WITH_TIME = 'MMMM dd, yyyy, h:mm a';

export const FILE_FORMATS = {
  csv: 'csv',
  xls: 'xls',
  xlsx: 'xlsx',
  pdf: 'pdf'
};

export const CAT_LOGO =
  'http://s7d2.scene7.com/is/image/Caterpillar/catcorp?fmt=png-alpha&hei=36&qlt=85,0&resMode=sharp2&op_usm=1.75,0.3,2,0';

export const DEV_CSR_ROUTE = '/webapp/wcs/stores/servlet';

export const LINK_CAT_HELP_CENTER_V2 =
  'https://cat-crm.force.com/HelpCenter/s?language={0}&geoloc={1}&pccCurrentPage={2}';

export const CAT_HELP_CENTER_V2_URL = 'https://cat-crm.force.com';
export const NEW_SALESFORCE_HELPCENTER_URL = 'https://catcrm.my.site.com';
export const CAT_HELP_CENTER_V2_URL_DEV =
  'https://catcrm--crmiuat.sandbox.my.site.com';
export const CAT_HELP_CENTER_V2_ENDPOINT = 'HelpCenter/s';
export const CAT_HELP_CENTER_V2_CONTACT_SUPPPORT_ENDPOINT =
  'HelpCenter/s/contactsupport';
export const CAT_HELP_CENTER_V2_FAQ_ENDPOINT = 'HelpCenter/s/faqs';

export const SORTING_DATE = 'DATE';
export const CAT_COOKIE_DOMAIN = '.cat.com';
export const CAT_IMPORT_LIST_FLAG = 'catImportListFlag';
export const DISPLAY_DATE = 'MM/dd/yy';
export const DISPLAY_DATE_US = 'MM/dd/yyyy';
export const DATE_US = 'MM/DD/YYYY';
export const DATE_US_SHORT_MONTH = 'M/dd/yyyy';
export const DATE_FORMAT_M_D_YY = 'M/d/yy';
export const DATE_FORMAT_OMM = 'yyyy/MM/dd';
export const DATE_FORMAT_YYYY_MM_DD = 'yyyy-MM-dd';
export const DATE_FORMAT_M_D_YYYY = 'M/d/yyyy';
export const DEFAULT_DATE_FORMAT = 'MM/dd/yy';
export const ABOUT_LOCAL_DEALER =
  'https://www.cat.com/en_US/support/dealer-locator.html';

export const UOM_COOKIE = {
  metric: 'MMT',
  us: 'INH'
};
export const ADD_TO_CART_ONCOMPLETE_RENDER = {
  MODAL: 'modal',
  TOAST: 'toast',
  SKIP: 'skip',
  BLOCKS_TOAST: 'blocks_toast'
};
export const TOAST_TYPE = {
  SUCCESS: 'success',
  FAIL: 'error'
};
export const TOAST_POSITION = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
  CENTER: 'center'
};

export const UOM_COOKIE_KEY = 'uom';

export const X_CAT_SESSION_ID_COOKIE_NAME = 'X-Cat-Pcc-Session-Id';

export const LOCALES_CORRECTED_MAP = {
  ar_EG: 'ar',
  cs_CZ: 'cs',
  da_DK: 'da',
  de_DE: 'de',
  en_US: 'en_US',
  fr_FR: 'fr',
  pl_PL: 'pl',
  in_ID: 'in',
  tr_TR: 'tr',
  he_IL: 'iw',
  ja_JP: 'ja',
  ko_KR: 'ko',
  nl_NL: 'nl_NL',
  nb_NO: 'no',
  pt_BR: 'pt_BR',
  ru_RU: 'ru',
  sv_SE: 'sv',
  th_TH: 'th',
  zh_CN: 'zh_CN',
  es_MX: 'es',
  zh_TW: 'zh_TW',
  hi_IN: 'hi_IN',
  ta_IN: 'ta_IN',
  it_IT: 'it',
  bn_BD: 'bn_BD',
  vi_VN: 'vi_VN',
  el_GR: 'el_GR',
  bg_BG: 'bg_BG',
  kn_IN: 'kn_IN',
  fi_FI: 'fi_FI'
};

export const PAGES_META_VALUES = {
  HOME_PAGE: {
    name: 'HomePage',
    pageIdentifier: 'HomePage'
  },
  SEARCH_PAGE: {
    name: 'Search',
    pageGroup: 'Search'
  },
  CATEGORY_PAGE: {
    name: 'Category',
    pageGroup: 'Category'
  },
  PRODUCT_DETAIL_PAGE: {
    name: 'PDP',
    pageGroup: 'Product'
  },
  MACHINE_LANDING_PAGE: {
    name: 'MLP',
    pageGroup: 'MachineLanding'
  },
  CAMPAIGN_LANDING_PAGE: {
    name: 'CLP',
    pageGroup: 'Content'
  },
  SITE_MAP_PAGE: {
    name: 'SiteMap',
    pageGroup: 'SiteMap'
  },
  MY_ACCOUNT_PAGE: {
    name: 'MyAccount',
    pageGroup: 'MyAccount'
  }
};
export const ONETRUST = {
  FUNCTIONAL_COOKIES: 'C0003',
  FUNCTIONAL_COOKIES_ACCEPTED: 'C0003%3A1',
  FUNCTIONAL_COOKIES_REJECTED: 'C0003%3A0',
  TARGET_COOKIES: 'C0004',
  TARGET_COOKIES_ACCEPTED: 'C0004%3A1',
  TARGET_COOKIES_REJECTED: 'C0004%3A0',
  GROUP_UPDATED_EVENT_LISTENER: 'OneTrustGroupsUpdated',
  OPT_CONSENT_COOKIE: 'OptanonConsent'
};
export const NOTIFICATION_STATUS = {
  DANGER: 'danger',
  APPROVED: 'APR'
};

export const PENDING_QUOTE_ACTIONS = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  PLACE_ORDER: 'PLACE_ORDER'
};

export const PACKAGE_BEAN = 'PackageBean';
export const BUNDLE_BEAN = 'BundleBean';
export const PRODUCT_BEAN = 'ProductBean';
export const SCENE7_BASE_URL =
  'https://caterpillar.scene7.com/is/image/Caterpillar/';

export const DEFAULT_OPTION = {
  displayName: 'BILL_BILLING_SELECT_BILLING_METHOD',
  value: '',
  isDefault: 'true'
};

export const BILLING_METHOD_TYPE_PRIMARY = 'primary';
export const BILLING_METHOD_TYPE_SECONDARY = 'secondary';

export const SITEMAP = 'SiteMapPage';

export const PICK_UP_TYPE = 'pickup';
export const PARTIAL_PICK_UP_TYPE = 'pickup';
export const DELIVERY_TYPE = 'delivery';
export const DROP_BOX_TYPE = 'dropBox';
export const SHOP_ONLINE_TYPE = 'shopOnline';
export const CONTENT_SQUARE_MASK = true;

export const LINK_HISTORY_MESSAGES = {
  success: 'Successfully exported link history.',
  error: 'Failed to export link history.'
};
export const MILLISECONDS_DEBOUNCE = 500;

export const IN_STOCK = 'In Stock';
export const ORDER_SUBMITTED_AND_PENDING_APPROVAL = 'W';
export const ORDER_STATUS_NOT_AVAILABLE = 'Not Available';
export const SECONDS_PER_DAY = 86400;
export const MILLISECONDS_IN_A_SECOND = 1000;

export const ACTIVE_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20210322-f8bd6-dc033?wid=32&hei=32&fmt=png-alpha';
export const INACTIVE_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20210322-d4c55-06b16?wid=32&hei=32&fmt=png-alpha';
export const ACTIVE_DROPBOX_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20230120-d1f06-25cee?wid=32&hei=32&fmt=png-alpha';
export const INACTIVE_DROPBOX_MARKER_ICON =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20230120-58d45-1a401?wid=32&hei=32&fmt=png-alpha';
export const ACTIVE_DROPBOX_MARKER_ICON_MOBILE =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20230120-d1f06-25cee?wid=16&hei=16&fmt=png-alpha';
export const INACTIVE_DROPBOX_MARKER_ICON_MOBILE =
  'https://caterpillar.scene7.com/is/image/Caterpillar/CM20230120-58d45-1a401?wid=16&hei=16&fmt=png-alpha';

export const LOCATION_HASH_KEY = {
  SHOP_PARTS: '#shopParts',
  PM_KITS: '#pmKits',
  OMM: '#OMMManuals',
  MAINTENANCE_REPAIRS: '#maintenanceAndRepairs',
  SERVICE_INSIGHT: '#serviceInsight'
};
export const CAT_DEALER_SHIPPING_TAX_PREFERENCES = 'I';
export const ACTION = 'action';
export const ACTION_TYPE = {
  approve: 'approve',
  delete: 'delete',
  reject: 'reject',
  updateComments: 'updateComments'
};

export const ORDER_TYPES = {
  OFFLINE_ORDER: 'oo',
  PART_STORE: 'psa',
  PCC: 'pcc'
};
export const CAT_CREDIT = 'Cat Credits';

export const CONSENT_SESSION_KEY = 'consent';

export const PCC_MODALS = {
  SELECT_STORE: 'selectstore',
  MY_EQUIPMENT: 'myequipment',
  REGISTER: 'register'
};

export const CWSID = 'cwsId';
export const PIX_PAYMENT_STATUS_TYPES = {
  PENDING: 'Pending',
  SUCCEED: 'Succeed',
  FAILED: 'Failed'
};

export const AVAIL_BY_DATE = 'availabilityByDateToggle';
export const AVAIL_BY_SOURCE = 'availabilityBySourceToggle';

export const BFF_ENDPOINT_HOST = 'http://localhost.cat.com:4200/';

export const CONTACT_DEALER_CARDS = ['OH', 'EX'];

export const NOTIFICATION_ORDER_LINK_TEXT = {
  W: 'REFERENCE_ID',
  N: 'REFERENCE_ID',
  AD: 'REFERENCE_ID',
  H: 'REFERENCE_ID',
  APR: 'REFERENCE_ID',
  OP: 'ORDER',
  OC: 'ORDER',
  OR: 'ORDER',
  NEW: 'QUOTE',
  OH: 'ORDER',
  EX: 'ORDER',
  DL: 'ORDER',
  RP: 'ORDER',
  PA: 'ORDER',
  PU: 'ORDER',
  QX: 'ORDER',
  FS: 'ORDER',
  PS: 'ORDER',
  EXP: 'QUOTE',
  E: 'REFERENCE_ID'
};

export const NOTIFICATION_INDICATOR = {
  OH: 'CONTACT_YOUR_DEALER',
  EX: 'CONTACT_YOUR_DEALER',
  W: 'REVIEW_ORDER',
  DL: 'VIEW_ORDER',
  RP: 'VIEW_ORDER',
  PA: 'VIEW_ORDER',
  PU: 'VIEW_ORDER',
  FS: 'TRACK_ORDER',
  PS: 'TRACK_ORDER',
  NEW: 'REVIEW_QUOTE',
  QX: 'RESUBMIT_ORDER_CTA',
  EXP: 'VIEW_QUOTE',
  E: 'RESUBMIT_ORDER_CTA'
};

export const SHOW_REFERENCE_ID = ['OP', 'QX'];

export const ORDER_PROCESSED = 'PROCESSED';

export const USER_NOTIFICATION_PAGESIZE = 25;
export const ALL = 'ALL';

export const APPROVED_ORDER_STATUS = ['OP', 'OH'];
export const PENDING_STATUS = 'Pending';

export const REQUEST_ID_HEADER = 'x-cat-api-tracking-id';

export const APPROVAL_INFO_STATUS = {
  approved: 'approved'
};

export const ORDER_HISTORY_DETAILS_GA = 'PCC ORDER HISTORY DETAILS';
export const EQUIPMENT_VALUE = 'equipmentValue';
export const EQUIPMENT = 'equipment';
export const SESSION = 'session';

export const EXPIRED_ORDER_STATUS = 'E';
export const EXPIRING_ORDER_STATUS_TYPES = ['E', 'W'];
export const SORT_TYPE = {
  ALPHANUMERIC: 'ALPHANUMERIC',
  NUMERIC: 'NUMERIC',
  DATE: 'DATE',
  CURRENCY: 'CURRENCY',
  EQUIPMENT: 'EQUIPMENT'
};
export const SORT_DIRECTIONS = {
  ASC: 'ascending',
  DESC: 'descending'
};

export const EN_LOCALE = 'en_US';
export const PO_NUMBER_PROHIBITED_REGEX =
  /.*(<|>|javascript|onerror|onload|onmouseout|onmouseover|onkeydown|onblur|onclick|onfocus|vbscript|=|\+|@|0x09|0x0D).*/;

export const GA_LOCATION_NO_EQ = 'shop parts for your cat equipment';
export const GA_LOCATION_FOR_EQ =
  'your equipment | Do you want to add more equipment';

export const GA_LOCATION_FOR_MEQ = 'Add your equipment to find parts that fit';

export const SEARCH_FILTER = {
  MODEL: 'model',
  SERIAL: 'serialNumber'
};

export const SEARCH_LENGTH = {
  MIN: 1,
  MAX: 8
};

export const ADD_EQP_DRAWER_ID = 'blocks-add-eqp';
export const EDIT_EQP_DRAWER_ID = 'blocks-edit-eqp';
export const SELECT_EQP_DRAWER_ID = 'blocks-select-eqp';
export const SELECTED_EQP_DRAWER_ID = 'blocks-selected-eqp';
export const SIS_EQP_DRAWER_ID = 'blocks-sis-eqp';
export const PROD_ENVS = ['prodauth', 'prodlive', 'prodalive', 'prodblive'];

export const XXL_BLOCKS_SKELETON_HEIGHT = 384;
export const BLOCKS_SKELETON_SIZES = ['sm', 'lg', 'xl', 'xxl'];

export const MODAL_SIZE = { SM: 'sm' };

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  CONTRAST: 'contrast',
  GHOST: 'ghost',
  DANGER: 'danger'
};

export const BUTTON_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

export const LIST_ITEMS_SIZES = {
  SM: 'sm',
  LG: 'lg',
  STATIC: 'static'
};

export const TOAST_VARIANT = {
  SUCCESS: 'success',
  ERROR: 'error',
  NONE: 'none'
};
export const KEBAB_OPTIONS = {
  ADD_TO_CART: 'addtocart',
  MARK_FAVORITE: 'markfavorite',
  DUPLICATE_LIST: 'duplicatelist',
  DELETE_LIST: 'deletelist',
  APPROVE_LIST: 'approve',
  REJECT_LIST: 'reject',
  VIEW_LIST: 'viewAll'
};

export const ORDER_STATUS_APPROVED = 'Approved';
export const ORDER_STATUS_PENDING = 'Pending';
export const ORDER_STATUS_SUBMITTED = 'Submitted';
export const orderStatusList = [
  {
    displayValue: ORDER_STATUS_APPROVED,
    value: ORDER_STATUS_APPROVED
  },
  {
    displayValue: ORDER_STATUS_SUBMITTED,
    value: ORDER_STATUS_PENDING
  }
];

export const NOTIFICATION_ORDER_STATUS = {
  OR: 'SHIPPED_STATUS_ON',
  RP: 'SHIPPED_STATUS_RP',
  PA: 'PARTIALLY_READY_PICKUP',
  PU: 'SHIPPED_STATUS_PU',
  OC: 'CANCELLED_STATE',
  PS: 'MO_OrderStatus_V',
  FS: 'SHIPPED_STATUS_FS',
  DL: 'SHIPPED_STATUS_DL',
  EX: 'ORDER_PROBLEM',
  OH: 'SHIPPED_STATUS_OH',
  QX: 'QUEUED_ORDER_NOT_PROCESSED_HEADING',
  H: 'QUEUED_FOR_PROCESSING'
};

export const NotificationDependantStatus = ['N', 'AD'];

export const SIS = 'Service Information System (SIS)';
export const SIS_TERM = 'SIS';
export const SIS_ANCHOR_ID = '#SISAnchorID';

export const EQUIPMENT_SERIAL_NUMBER = 'equipmentSerialNumber';
export const SERIAL_NUMBER = 'serialNumber';
export const ASSET_ID = 'assetId';
export const MODEL = 'model';
export const ORDERS_TO_APPROVE_BLOCKS_FLAG = 'PCC_ordersToApproveBlocksFlag';

export const GuestEqRoutePath = '/GuestUserMyEquipmentView';
export const EQUIPMENT_FAMILY = 'equipmentFamily';
export const SEARCH_KEYS = [SERIAL_NUMBER, EQUIPMENT_FAMILY, ASSET_ID, MODEL];
export const PREFERENCES_CONTEXT = {
  isReadyForPickupNotificationPreferred_Web: 'RP',
  isReadyForPartiallyPickupNotificationPreferred_Web: 'PA',
  isPickedUpNotificationPreferred_Web: 'PU',
  isPartiallyShippedNotificationPreferred_Web: 'PS',
  isDeliveredNotificationPreferred_Web: 'DL',
  isFullyShippedNotificationPreferred_Web: 'FS',
  isOrdProcOrdProcessingPreferred_Web: 'OR',
  isOrdApprOrderExpiredPreferred_Web: 'E',
  isOrderExceptionNotificationPreferred_Web: 'EX',
  // isOrdApprWeeklyApprSummaryPreferred_Web: '',
  // isOrdApprOrderExpiredPreferred_Web: 'EXP',
  isQuoteReminderExpiredPreferred_Web: 'EXP',
  isQuoteReminderExpiringTodayPreferred_Web: 'NEW',
  orderApprovalLimitRange: 'LIMIT'
};
export const GA_FORM_NAME_FOR_NOTIFICATION_FILTER =
  'Notification Drawer Filter';
export const GA_FORM_CONTENT_FOR_NOTIFICATION_REFERENCE_NUMBER =
  'Order/Quote reference Number';
export const GA_FORM_FOR_ORDER_STATUS = 'drop down selected All status';
export const GA_FORM_ERROR_FOR_NOTIFICATION_FILTER = 'order/quote not found';

export const URGENT_EXPIRES_HIGH = 4;
export const DEFAULT_EXPIRES = 7;
export const URGENT_EXPIRES_DAY = 1;
export const EXPIRE_DAYS_ZERO = 0;

export const ENTER_KEY = 'Enter';
export const SUB_YEARS = 2;
export const ADD_DAYS = 90;

export const CAL_NOTIFICATIONS = ['W', 'N', 'AD', 'APR', 'E'];

export const ALP_HOW_TO_VIDEOS = {
  alp_Videos_path: '{0}/AlpMaintenanceVideosView?partNumber={1}&storeId={2}'
};
export const REST_CONTEXT_PATH = '/wcs/resources';

export const KOREAN_CURRENCY = 'WON';
export const KOREAN_CURRENCY_SYMBOL = '원';

export const INDUSTRY_CODE = {
  CI: 'CI',
  ET: 'ET'
};

export const BLOCKS_HEADER_HEIGHT = 136;
export const PENDING_QUOTES_BLOCKS_FLAG = 'PCC_BlocksPendingQuotesFlag';

export const DRAWER_WIDTH = {
  FULL: '100%',
  MID: '75%'
};

export const CSR_BANNER_HEIGHT = 56;

export const CAT_MARKETING_FEATURES = {
  CAT_CARD: 'Card'
};

export const CILG_ITEMS = 'saveCILGCartsItem';
export const PCC_ALP_NON_RELATED_CATEGORIES = 'PCC_ALP_NON_RELATED_CATEGORIES';

export const PCC_BUILD_CART_LINK_CAMPAIGN_ID = 'CDS_eCommerce_CSR';

export const METHOD = 'Method';
export const ADDRESS = 'Address';
export const INSTRUCTION = 'Instructions';
export const DROPBOX = 'Dropbox';
export const DROPBOX_METHOD = 'Dropbox Method';
export const DROPBOX_INSTRUCTION = 'Dropbox Instructions';
export const DROPBOX_ADDRESS = 'Dropbox Address';
export const DELIVERY_SHIP_CODE = 'DeliveryShipViaCode';
export const LAST_FULFILLMENT_DATE_CHANGED = 'lastFulFillmentDateModified';
export const RBD_UPDATE_FROM_SUMMARY_AND_PAYMENT =
  'rbdUpdateFromSummaryAndPayment';
export const ELIGIBLE_RETURN_STATUS = ['PS', 'FS', 'DL', 'PA', 'RP', 'PU'];
export const HAS_RBD_UPDATED_IN_DB = 'hasRbdUpdatedInDB';
export const FULFILMENT_DATE_CHANGED = 'fulfilmentDateChanged';
export const LOGIN_FROM_HOME_PAGE = 'loginFromHomePage';

export const TRACTAFRIC_COUNTRIES_HIDE_MAP = new Set([
  'MA',
  'CM',
  'TD',
  'GA',
  'CG',
  'CF',
  'GQ',
  'RW',
  'BI'
]);

export const TRACTAFRIC_DEALER_CODE = 'K600';
export const SAME_MONTHS = 'sameMonths';
export const SINGLE_MONTH = 'singleMonth';
export const DIFFERENT_MONTHS = 'differentMonths';
export const IS_TAX_FREIGHT_INITIAL_CALL_OVER = 'isTaxFringhtInitialCallOvr';

export const IS_STICKY_SCROLL_TOP = 100;
export const HEADER_STICKY_ON_SCROLL = 'header-sticky-on-scroll';
export const HEADER_OVERLAY_ON_MOBILE = 'header-overlay-on-mobile';
export const STICKY_BANNER_ON_SCROLL = 'sticky-banner-on-scroll';
export const STICKY_TIMEOUT = 300;
export const DYNAMIC_NOTIFICATION_TRANSLATION = {
  ...ORDER_STATUS_NOTIFICATION_TRANSLATION_MAP,
  OP: 'NOTIFICATION_QUEUED_PROCESSING',
  APR: 'NOTIFICATION_QUEUED_PROCESSING',
  N: 'NOTIFICATION_QUEUED_PROCESSING',
  AD: 'NOTIFICATION_QUEUED_PROCESSING',
  PS: 'NOTIFICATION_QUEUED_PROCESSING',
  FS: 'NOTIFICATION_QUEUED_PROCESSING',
  DL: 'NOTIFICATION_QUEUED_PROCESSING',
  OR: 'NOTIFICATION_PICKED_READY',
  NEW: 'NOTIFICATION_PICKED_READY',
  E: 'NOTIFICATION_FULLY_SHIPPED',
  EX: 'NOTIFICATION_ORDER_PROBLEM'
};

export const NOTIFICATION_HEADER_STATUS = {
  ...NOTIFICATIONS_STATUS_MAP,
  PS: 'MO_OrderStatus_V',
  H: 'QUEUED',
  QX: 'NOT_PROCESSED',
  OR: 'CREDIT_CARD_PROCESSING',
  NEW: 'EXPIRING_TODAY',
  E: 'NOTIF_PREF_EXPIRED_APPROVAL_TITLE',
  EX: 'ORDER_PROBLEM'
};

export const DESKTOP_STORE_LOCATOR_MESSAGE = {
  HOMEPAGE: 'HOME_PAGE_DESKTOP',
  MLP: 'ALP_PAGE',
  PLP: 'PLP_PAGE',
  SEARCHPAGE: 'SEARCH_RESULT_PAGE'
};

export const REJECT_ORDER = 'N';
export const CONTROL_CENTER_BLOCK_FLAG = 'PCCControlCenterFlagBlocks';
export const srUserUpdateStatus = {
  ACTIVE: 'active',
  SEEN: 'seen'
};

export const FOCUS_SERVICE_INSIGHT = 'focusServiceInsight';

export const OFFER_STATUS_CODE = ['notified'];

export const CAT_CARD_MENU_CRITERIA_COUNTRY = { US: 'US', CA: 'CA' };
export const CAT_CARD_MENU_CRITERIA_LANGID = { en_US: 'en_US', fr_FR: 'fr_FR' };
export const CAT_CARD_MENU_FRENCH_TITLE = 'La Carte Cat';

export const CAT_CARD_US_AND_CANADA_COUNTRY_CODES = ['US', 'CA'];

export const HTML_SPECIAL_CHAR_REGEX = /[<>[\]\\{}]/;
export const HTML_SPECIAL_CHAR_ERROR = '\\, <, >, [, ], {, }';

export const LUCID_CATEGORY_API_PARAMS = {
  subCategoryLevel: 2,
  subCategoryLimit: 20
};

export const FEATURED_PRODUCTS = 'featuredParts';
export const RECENTLY_VIEWED = 'recentlyViewed';

export const COMMA = ',';

export const ERROR_IMAGE = {
  imgUrl:
    'https://caterpillar.scene7.com/is/image/Caterpillar/CM20240523-b8387-22a51?&fmt=webp&wid=180&hei=109',
  alt: 'error image'
};

export const PART_QTY = 'partNumber_QTY';
