import {
  FORM_ADD_EQ_SUGGESTION_SUBMITTED_NAME,
  STATUS_FAIL,
  STATUS_SUCCESS
} from '@app/constants/analyticsConstants';
import endpoints from '@app/constants/endpoints';
import { useAnalytics, useHttp } from '@app/hooks';
import { replaceTokensInUrl } from '@app/utils';
import debounce from 'lodash/debounce';
import { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MILLISECONDS_DEBOUNCE,
  SEARCH_FILTER
} from '@app/constants/commonConstants';
import { PCC_LUCID_SOLR_ADDEQUIPMENTCOMPONENT_FLAG } from '@app/constants/featureFlags';
import {
  setError as setSystemError,
  clearErrors
} from '@app/store/myequipment/actions';
import { useTranslation } from 'react-i18next';
import { replaceFromString } from '@app/utils/stringUtils';

const useEquipmentSearchSuggestions = () => {
  const [t] = useTranslation();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const { invoke, loading } = useHttp();
  const { fireFormSubmittedEvent } = useAnalytics();
  const dispatch = useDispatch();
  const isLucidAddEquipmentFeatFlag = useSelector(
    s => s.featureFlag[PCC_LUCID_SOLR_ADDEQUIPMENTCOMPONENT_FLAG]
  );

  const { langId, catalogId, storeId, locale, storeIdentifier } = useSelector(
    state => state.common
  );

  const sendFormSubmittedEvent = useCallback(
    ({
      formContent,
      formStatus,
      formFieldCausingError = '',
      formLocation = '',
      formName = FORM_ADD_EQ_SUGGESTION_SUBMITTED_NAME
    }) => {
      fireFormSubmittedEvent({
        pagePath: document.location.pathname,
        formName,
        formContent,
        formStatus,
        formLocation,
        formFieldCausingError
      });
    },
    [fireFormSubmittedEvent]
  );

  const onSuggestionsSearchSuccess = useCallback(
    (serialNumber, data, updateSearchState, gaForm) => {
      setError(false);
      setErrorText('');
      const equipmentList = data.equipments ?? data.matchedEquipment ?? [];
      const resultsList = equipmentList.map(item => ({
        ...item,
        imageURL: item.imageURL ?? item.imageUrl,
        ...(isLucidAddEquipmentFeatFlag && {
          equipmentFamily: replaceFromString(item.model, item.name)
        })
      }));
      const otherEquipmentsList =
        data.otherEquipments ?? data.partiallyMatchedEquipment;
      const otherResultList = otherEquipmentsList.map(item => ({
        ...item,
        imageURL: item.imageURL ?? item.imageUrl,
        equipmentFamily: replaceFromString(item.model, item.name)
      }));

      updateSearchState({
        query: serialNumber,
        machinesCount: data.machinesCount ?? data.matchedEquipmentsCount,
        otherResults: otherResultList,
        results: serialNumber.length >= 2 && resultsList,
        success: !!(
          serialNumber.length >= 2 &&
          ((data.equipments ?? data.matchedEquipment) ||
            (data.otherEquipments ?? data.partiallyMatchedEquipment))
        )
      });
      sendFormSubmittedEvent({
        formContent: serialNumber,
        formStatus: STATUS_SUCCESS,
        ...gaForm
      });
    },
    [sendFormSubmittedEvent]
  );

  const onSuggestionsSearchFail = useCallback(
    (serialNumber, data, updateSearchState, gaForm) => {
      const errorMessage = data?.errorKey ?? data?.errors[0]?.errorMessage;
      updateSearchState({
        query: serialNumber,
        results: [],
        otherResults: [],
        success: false
      });
      if (errorMessage) {
        setError(true);
        setErrorText(errorMessage);
      } else {
        dispatch(setSystemError({ response: { status: '' } }));
      }
      sendFormSubmittedEvent({
        formContent: serialNumber,
        formStatus: STATUS_FAIL,
        formFieldCausingError: errorMessage,
        ...gaForm
      });
    },
    [dispatch, sendFormSubmittedEvent]
  );

  const invokeDebounce = useMemo(
    () =>
      debounce(
        (serialNumber, updateSearchState, filter, gaForm) => {
          let url = replaceTokensInUrl(
            endpoints.EQUIPMENT_SEARCH,
            serialNumber.toLowerCase(),
            catalogId,
            langId,
            storeId
          );
          if (filter) {
            url = isLucidAddEquipmentFeatFlag
              ? replaceTokensInUrl(
                  endpoints.MACHINE_SEARCH,
                  locale,
                  storeIdentifier,
                  serialNumber.toLowerCase(),
                  filter
                )
              : replaceTokensInUrl(
                  endpoints.EQUIPMENT_SEARCH_REST,
                  storeId,
                  serialNumber.toLowerCase(),
                  catalogId,
                  langId,
                  filter
                );
          }
          invoke({ method: 'get', rethrowError: true, url: url })
            .then(data => {
              dispatch(clearErrors());
              if (
                data.equipments ||
                data.otherEquipments ||
                data.matchedEquipment ||
                data.partiallyMatchedEquipment
              ) {
                onSuggestionsSearchSuccess(
                  serialNumber,
                  data,
                  updateSearchState,
                  gaForm
                );
              } else if (data.errors) {
                onSuggestionsSearchFail(
                  serialNumber,
                  data,
                  updateSearchState,
                  gaForm
                );
              } else {
                const errorKey =
                  SEARCH_FILTER.MODEL == filter
                    ? 'MEQ_MODEL_NO_MATCH'
                    : 'MEQ_SERIAL_NO_MATCH';
                const noResponseError = {
                  errorKey: t(errorKey)
                };
                onSuggestionsSearchFail(
                  serialNumber,
                  noResponseError,
                  updateSearchState,
                  gaForm
                );
              }
            })
            .catch(({ response }) => {
              onSuggestionsSearchFail(
                serialNumber,
                response?.data,
                updateSearchState,
                gaForm
              );
            });
        },
        MILLISECONDS_DEBOUNCE,
        {
          leading: true,
          trailing: true
        }
      ),
    [
      invoke,
      catalogId,
      langId,
      storeId,
      isLucidAddEquipmentFeatFlag,
      locale,
      storeIdentifier,
      dispatch,
      onSuggestionsSearchSuccess,
      onSuggestionsSearchFail,
      t
    ]
  );

  const callSuggestionsSearchService = (
    serialNumber,
    updateSearchState,
    filter = '',
    gaForm = {}
  ) => {
    updateSearchState({
      query: serialNumber,
      results: [],
      otherResults: [],
      success: false
    });
    invokeDebounce(serialNumber, updateSearchState, filter, gaForm);
  };

  return {
    loading,
    error,
    setError,
    errorText,
    setErrorText,
    callSuggestionsSearchService,
    sendFormSubmittedEvent
  };
};

export default useEquipmentSearchSuggestions;
